import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ImageKaterina from "./imagekaterina";

export const KaterinaPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container" style={{ maxWidth: "746px" }}>
        <div className="section">
        <div style={{ display: "grid", placeItems: "left" }}>
            <ImageKaterina />
          </div>
          <h3 className="title has-text-weight-semibold is-size-3 my-5 is-family-secondary" style={{ color: "#6ba696" }}>
            {title}
          </h3>
          <PageContent className="content" content={content} />
        </div>
      </div>
    </section>
  );
};

KaterinaPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const KaterinaPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <KaterinaPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

KaterinaPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default KaterinaPage;

export const KaterinaPageQuery = graphql`
  query KaterinaPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
